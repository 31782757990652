import React, {
  useState,
  useEffect,
  useCallback,
  startTransition,
} from "react";
import API from "../../../API/ServiceLayer"; // Your API service layer
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import URL from "../../../API/Api";
import Swal from "sweetalert2";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { FaEye } from "react-icons/fa";
import "./Tree.css";

const Tree = () => {
  const [orgData, setOrgData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(13);
  const [showModal, setShowModal] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  //edit process
  const [showEditModal, setShowEditModal] = useState(false);
  const [editProcessId, setEditProcessId] = useState(null);
  const [editProcessName, setEditProcessName] = useState("");
  const [editOrgId, setEditOrgId] = useState("");
  const [editFlowOrder, setEditFlowOrder] = useState("");
  const [editDept, setEditDept] = useState([]);
  const [departments, setDepartments] = useState([]); // Assumes departments are fetched
  const [organizations, setOrganizations] = useState([]);
  const [allOrgWithProcess, setAllorgwithProcess] = useState([]);

  //edit Task
  // Show the modal state
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  // State variables for task details (taskEdit prefix followed by field name)
  const [taskEditOrgID, setTaskEditOrgID] = useState("");
  const [taskEditPrcsID, setTaskEditPrcsID] = useState("");
  const [taskEditDepID, setTaskEditDepID] = useState("");
  const [taskEditDeptRoleID, setTaskEditDeptRoleID] = useState("");
  const [taskEditTaskName, setTaskEditTaskName] = useState("");
  const [taskEditSLA, setTaskEditSLA] = useState("");
  const [taskEditFloOrder, setTaskEditFloOrder] = useState("");
  const [taskEditDocum, setTaskEditDocum] = useState(false);
  const [taskEditTaskDesc, setTaskEditTaskDesc] = useState("");
  // Loading state for API requests
  const [loading, setLoading] = useState(false);

  // Dynamic state for process, department, and role options
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);

  const [taskDetails, setTaskDetails] = useState(null);
  const [usersList, setUsersList] = useState([]); // to store users based on dept and role selection
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    // Populate selectedUsers based on is_assigned (from the taskDetails)
    if (taskDetails && taskDetails.users) {
      const assignedUsers = taskDetails.users
        .filter((user) => user.is_assigned) // Filter only those users who are already assigned
        .map((user) => user.user_id); // Get the user_ids of the assigned users
      setSelectedUsers(assignedUsers);
    }
  }, [taskDetails]); // This will run every time taskDetails changes

  const handleUserSelection = (userId, isChecked) => {
    // Handle when a user is selected or deselected
    if (isChecked) {
      setSelectedUsers((prevState) => [...prevState, userId]); // Add user to selectedUsers
    } else {
      setSelectedUsers((prevState) => prevState.filter((id) => id !== userId)); // Remove user from selectedUsers
    }
  };
  // Fetch all organization
  const fetchAllOrg = async () => {
    try {
      const response = await API.getData("api/org_process");
      const result = await response.json();
      if (result.status) {
        setOrgData(result.data);
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };

  useEffect(() => {
    fetchAllOrg();
  }, []);

  useEffect(() => {
    if (selectedOrg !== null) {
      const selectedData = orgData.find((org) => org.org_id === selectedOrg);
      setSelectedOrgData(selectedData);
    }
  }, [orgData, selectedOrg]);

  // Fetch Departments
  const fetchDepartments = useCallback(async () => {
    try {
      const response = await URL.get(
        "https://dev.berighthere.com/user-management/api/v1/orgdept"
      );
      setDepartments(response.data.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  // Fetch Organizations
  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("https://dev.berighthere.com/api/allorg");
      setOrganizations(response.data.data);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  // Fetch Departments and Organizations on component mount
  useEffect(() => {
    fetchDepartments();
    fetchOrganizations();
  }, [fetchDepartments, fetchOrganizations]);

  // Fetch the organization and process data
  const fetchOrganizationProcesses = useCallback(async () => {
    try {
      const response = await URL.get("/api/org_process");
      setAllorgwithProcess(response.data.data);
      console.log("response.data.data", response.data.data);
    } catch (error) {
      console.error("Error fetching organization processes:", error);
    }
  }, []);

  useEffect(() => {
    fetchOrganizationProcesses();
  }, [fetchOrganizationProcesses]);

  const gradientColors = [
    "linear-gradient(135deg, #FF9E30, #FFB74D)", // Slightly deeper orange tone
    "linear-gradient(135deg, #B0B0B0, #D1D1D1)", // Soft grey with depth
  ];

  const gradientColorsTask = ["linear-gradient(135deg, #FFB347, #FFCC33)"];

  // Handler for setting the process flow order
  const SettingProcessOrderHandler = (org_id) => {
    console.log("Selected Org ID:", org_id);

    const organization = allOrgWithProcess.find(
      (org) => org.org_id === parseInt(org_id)
    );

    if (organization) {
      console.log("Found Organization:", organization);

      if (organization.processes && organization.processes.length > 0) {
        const lastProcess =
          organization.processes[organization.processes.length - 1];
        const lastFlowOrder = lastProcess.process_flow_order;

        setEditFlowOrder(lastFlowOrder + 1);
      } else {
        setEditFlowOrder(1);
      }
    } else {
      console.log("Organization not found or empty processes.");
    }
  };

  // Handle Organization Change
  const handleOrgChange = (e) => {
    const selectedOrgId = e.target.value;
    setSelectedOrg(Number(e.target.value));
    setEditOrgId(selectedOrgId);
    SettingProcessOrderHandler(selectedOrgId);
  };

  // Open the modal for the selected process
  const handleProcessClick = (process) => {
    setSelectedProcess(process);
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProcess(null);
  };

  //capitalizeWords
  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Handle drag end event for reordering tasks
  const onTaskDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    console.log(destination, source, "<><>>");
    const reorderedTasks = Array.from(selectedProcess.tasks);
    const [movedTask] = reorderedTasks.splice(source.index, 1);
    reorderedTasks.splice(destination.index, 0, movedTask);

    // Update task_flow_order after reordering tasks
    reorderedTasks.forEach((task, index) => {
      task.task_flow_order = index + 1;
    });

    // Update the local state for tasks
    setSelectedProcess({
      ...selectedProcess,
      tasks: reorderedTasks,
    });

    // Send the updated task order to the backend
    try {
      await updateTaskFlowOrder(selectedProcess.process_id, reorderedTasks);
    } catch (error) {
      console.error("Error updating task flow order:", error);
    }
  };

  // Handle drag end event for reordering processes
  const onProcessDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    console.log("Before Reorder:", selectedOrgData.processes);

    const reorderedProcesses = Array.from(selectedOrgData.processes);

    const [movedProcess] = reorderedProcesses.splice(source.index, 1);
    reorderedProcesses.splice(destination.index, 0, movedProcess);

    console.log("After Reorder:", reorderedProcesses);

    reorderedProcesses.forEach((process, index) => {
      process.process_flow_order = index + 1;
    });

    console.log("Updated Process Flow Order:", reorderedProcesses);

    setSelectedOrgData((prevData) => {
      console.log("State Update Triggered");
      return {
        ...prevData,
        processes: reorderedProcesses,
      };
    });

    // Check if the UI is reflecting the updated order
    console.log("Selected Org Data After Update:", selectedOrgData);

    try {
      const response = await updateProcessFlowOrder(reorderedProcesses);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error updating process flow order:", error);
    }
  };

  const handleEditProcess = async (process) => {
    // Implement your edit logic here
    console.log("Edit process:", process.process_id);
    const response = await URL.get(`/api/process/${process.process_id}`);
    const processData = response.data.data;
    setEditProcessId(process.process_id);
    setEditProcessName(processData.process_name);
    setEditOrgId(processData.org_id);
    setEditFlowOrder(processData.process_flow_order);
    setEditDept(processData.dept_id);
    setShowEditModal(true);
  };
  const handleClose = () => setShowEditModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProcess = {
      org_id: editOrgId,
      process_name: editProcessName,
      process_flow_order: editFlowOrder,
      dept_id: editDept,
      status: 1,
    };

    console.log("Updating Process ID:", editProcessId);

    try {
      const response = await URL.put(
        `api/process/${editProcessId}`,
        updatedProcess
      );
      if (response.status === 200) {
        // Successfully updated, now update the state or refetch the data
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "Process Data Successfully Updated",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        // Optionally, refetch the process list
        fetchAllOrg(); // This function should re-fetch your list of processes

        setShowEditModal(false); // Close the modal
      }
    } catch (error) {
      console.error("Error updating process:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const updateProcessFlowOrder = async (changedProcesses) => {
    try {
      const payload = {
        org_id: selectedOrg,
        processes: changedProcesses.map((process) => ({
          process_id: process.process_id,
          process_flow_order: process.process_flow_order,
        })),
      };

      const response = await URL.post(
        "/api/update-process-flow-order",
        payload
      );

      console.log("Response:", response.data);

      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success",
        text:
          response.data.message || "Process Flow Order Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error updating process flow order:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message ||
          "There was an error updating the process flow order. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Function to send updated task order to the backend and show success/error notifications
  const updateTaskFlowOrder = async (processId, reorderedTasks) => {
    try {
      const response = await URL.post(
        `/api/update-task-flow-order/${processId}`,
        {
          tasks: reorderedTasks,
        }
      );

      // Show success notification
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Task Flow Order Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "rgb(255, 165, 0)",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error updating task flow order:", error);

      // Show error notification
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.message ||
          "There was an error updating the task flow order. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Function to render tasks
  const renderTasks = (tasks) => {
    const sortedTasks = [...tasks].sort(
      (a, b) => a.task_flow_order - b.task_flow_order
    );

    // If no tasks are assigned, show a message
    if (sortedTasks.length === 0) {
      return <div>No Task is Assigned</div>;
    }

    return (
      <DragDropContext onDragEnd={onTaskDragEnd}>
        <Droppable droppableId="tasks-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sortedTasks.map((task, index) => (
                <Draggable
                  key={task.task_id}
                  draggableId={task.task_id.toString()}
                  index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="task-container"
                      style={{
                        ...provided.draggableProps.style,
                        border: "1px solid #ddd",
                        padding: "8px",
                        margin: "4px 0",
                        background:
                          gradientColorsTask[
                            Math.floor(index / 1) % gradientColorsTask.length
                          ],
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      }}>
                      <div className="task-left">
                        <div className="task-name">
                          {capitalizeWords(task.task_name)}
                        </div>
                      </div>
                      <div className="task-right">
                        <div className="task-flow-order">
                          {task.task_flow_order}
                        </div>
                        {/* FaEye Icon */}
                        <FaEye
                          className="task-flow-order1"
                          onClick={() => handleEditTask(task)}
                          style={{
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const renderProcesses = (processes) => {
    const process = [...processes].sort(
      (a, b) => a.process_flow_order - b.process_flow_order
    );

    if (!process || process.length === 0) {
      return (
        <div
          style={{ textAlign: "center", fontSize: "16px", color: "#B0BEC5" }}>
          No Process is assigned
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={onProcessDragEnd}>
        <Droppable droppableId="processes-list" direction="vertical">
          {(provided) => (
            <ul
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                listStyleType: "none", // Remove default bullets
                padding: 0,
                margin: 0,
              }}>
              {process.map((process, index) => (
                <Draggable
                  key={process.process_id}
                  draggableId={process.process_id.toString()}
                  index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        marginBottom: "10px",
                        display: "flex", // Flexbox for centering
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically

                        ...provided.draggableProps.style, // Apply drag styles (transform, etc.)
                      }}>
                      <Card
                        className="process-card"
                        style={{
                          background:
                            gradientColors[
                              Math.floor(index / 1) % gradientColors.length
                            ],
                          width: "33%",
                          cursor: "pointer",
                          padding: "10px",
                          borderRadius: "15px",
                          overflow: "hidden",
                          position: "relative",
                          transition: "transform 0.2s ease-in-out",
                        }}
                        onClick={() => handleProcessClick(process)}>
                        <FaEdit
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log("Process onClick:", process);
                            handleEditProcess(process);
                          }}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            fontSize: "18px",
                            color: "white",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                        />
                        <Card.Body
                          className="card-body-animated"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            position: "relative",
                          }}>
                          {/* Circle for Process Flow Order */}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              backgroundColor: "rgba(255, 255, 255, 0.3)",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              color: "rgb(255 39 34)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}>
                            {process.process_flow_order}
                          </div>
                          {/* Glass Effect for Process Info */}
                          <div
                            style={{
                              background: "rgba(255, 255, 255, 0.3)",
                              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                              backdropFilter: "blur(10px)",
                              padding: "10px",
                              borderRadius: "8px",
                              color: "#4E5D6A",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                            }}>
                            <h6
                              style={{ fontWeight: "bold", color: "#2D3A45" }}>
                              {process.process_name}
                            </h6>
                            <div
                              style={{
                                color: "#B0BEC5",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}>
                              <strong style={{ color: "rgb(45 58 69 / 75%)" }}>
                                Tasks Assigned:
                              </strong>
                              <span
                                style={{
                                  fontWeight: "800",
                                  color: "rgb(87 106 93)",
                                  marginLeft: "5px",
                                }}>
                                {process.tasks?.length}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  // Get selected organization data
  const selectedOrgDataa = orgData.find((org) => org.org_id === selectedOrg);

  //task
  // Close modal
  const handleEditCloseTask = () => setShowEditTaskModal(false);

  // Fetch task details when viewing a task (by ID)
  const handleEditTask = (task) => {
    console.log(task, "task");
    console.log(task.task_id, "task.task_id");
    setShowEditTaskModal(true);
    setSelectedTask(task.task_id);
    GetTaskDetail(task.task_id);
  };

  // Fetch task data from the API
  const GetTaskDetail = async (taskId) => {
    try {
      const response = await URL.get(`/api/task/${taskId}`);
      const taskDetails = response.data.data;
      console.log("geeth", taskDetails);

      // Store taskDetails in state
      setTaskDetails(taskDetails);

      // Set state variables based on API response
      setTaskEditOrgID(taskDetails.org_id);
      setTaskEditPrcsID(taskDetails.process_id);
      setTaskEditDepID(taskDetails.dept_id);
      setTaskEditDeptRoleID(taskDetails.dept_role_id);
      setTaskEditTaskName(taskDetails.task_name);
      setTaskEditSLA(taskDetails.sla_resolution_time);
      setTaskEditFloOrder(taskDetails.task_flow_order);
      setTaskEditDocum(taskDetails.is_doc_upload_required === "1"); // Convert to boolean
      setTaskEditTaskDesc(taskDetails.task_description);

      // Set usersList and selectedUsers
      const usersWithAssignments = taskDetails.users || [];
      setUsersList(usersWithAssignments); // Set users list from API data

      // Set selectedUsers based on 'is_assigned'
      const selected = usersWithAssignments
        .filter((user) => user.is_assigned === true)
        .map((user) => user.user_id);
      setSelectedUsers(selected); // Populate selectedUsers with user_id of assigned users
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartmentId = parseInt(e.target.value, 10);
    setTaskEditDepID(selectedDepartmentId);

    // Find the department object in departmentOptions that matches the selected department ID
    const selectedDepartment = departmentOptions.find(
      (dept) => dept.value === selectedDepartmentId
    );

    if (selectedDepartment) {
      setRoleOptions(selectedDepartment.roles);

      // Set the default role ID (first role if available)
      const newRoleId =
        selectedDepartment.roles && selectedDepartment.roles.length > 0
          ? selectedDepartment.roles[0].value
          : null;
      setTaskEditDeptRoleID(newRoleId);

      // Check if taskDetails is available (should be true if GetTaskDetail has been called)
      if (taskDetails && taskDetails.users) {
        // Log users for debugging
        console.log("Users in taskDetails:", taskDetails.users);

        // Check if any users belong to the selected department (fallback filter)
        const filteredUsers = taskDetails.users.filter((user) => {
          // Here, you could apply any other filter logic based on the data you have
          // For now, just return true to show all users as a fallback
          return true;
        });

        // Update the users list in state
        setUsersList(filteredUsers);
      }
    } else {
      setRoleOptions([]);
      setTaskEditDeptRoleID(null);
      setUsersList([]); // Clear the user list if no department is selected
    }
  };

  // Handle task form submission
  const handleSubmitTask = async (e) => {
    e.preventDefault();

    if (!selectedTask) {
      console.error("No task selected for update");
      return;
    }

    const taskData = {
      org_id: taskEditOrgID,
      process_id: taskEditPrcsID,
      dept_id: taskEditDepID,
      dept_role_id: taskEditDeptRoleID,
      task_name: taskEditTaskName,
      sla_resolution_time: taskEditSLA,
      task_flow_order: taskEditFloOrder,
      is_doc_upload_required: taskEditDocum ? "1" : "0",
      task_description: taskEditTaskDesc,
      user_ids: selectedUsers,
    };

    try {
      const response = await URL.put(`/api/task/${selectedTask}`, taskData);
      if (response.status === 200) {
        console.log("Task updated successfully");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message || "Task Data Successfully Updated",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "rgb(255, 165, 0)",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        // Get the updated task from the response
        const updatedTask = response.data.data;

        // Update the task in the tasks list
        setSelectedProcess((prevProcess) => {
          // Update the task in the array of tasks
          const updatedTasks = prevProcess.tasks.map((task) =>
            task.task_id === updatedTask.id
              ? { ...task, ...updatedTask } // Merge updated task data into the existing task
              : task
          );

          // Return the updated process with the modified tasks list
          return { ...prevProcess, tasks: updatedTasks };
        });

        // Optionally, close the modal after submitting
        handleEditCloseTask();
      }
    } catch (error) {
      console.error("Error updating task:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Fetch process and department data based on selected organization and department
  useEffect(() => {
    const fetchProcessesAndDepartments = async () => {
      if (!taskEditOrgID) return; // Skip if no organization is selected
      setLoading(true);

      try {
        const response = await URL.get(`api/org/${taskEditOrgID}`);
        if (response.data && response.data.status) {
          const orgData = response.data.data;

          // Format processes for the dropdown
          const formattedProcessOptions = orgData.processes.map((process) => ({
            value: process.process_id,
            label: process.process_name || `Process ${process.process_id}`, // Fallback if process name is null
            key: `process-${process.process_id}`,
          }));
          setProcessOptions(formattedProcessOptions);

          // Format departments for the dropdown
          const formattedDepartments = orgData.departments.map((dept) => ({
            value: dept.department_id,
            label: dept.department_name,
            roles: dept.roles.map((role) => ({
              value: role.role_id,
              label: role.dep_role_name,
            })),
            key: dept.department_id,
          }));
          setDepartmentOptions(formattedDepartments);

          // If a department is already selected, set roles for that department
          if (taskEditDepID) {
            console.log(formattedDepartments, "formattedDepartments");
            console.log(taskEditDepID, "taskEditDepID");

            const selectedDept = formattedDepartments.find(
              (dept) => dept.value === taskEditDepID
            );
            console.log(selectedDept, "selectedDept");

            if (selectedDept) {
              // Check if roles exist for the selected department
              if (selectedDept.roles && selectedDept.roles.length > 0) {
                setRoleOptions(selectedDept.roles);
              } else {
                // If no roles, clear the roles
                setRoleOptions([]);
              }
            } else {
              // If department not found, clear roles
              setRoleOptions([]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching processes or departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcessesAndDepartments();
  }, [taskEditOrgID, taskEditDepID]);

  const SettingTaskOrderHandler = (prcs__id) => {
    console.log("prcs__id", prcs__id);
    URL.get("/api/allprocess_task")
      .then((res) => {
        console.log(res.data.data, "ddddkkklll");

        const TaskernAtion = res.data.data.find((prs) => prs.id == prcs__id);
        console.log(TaskernAtion, "getobj");
        if (TaskernAtion && TaskernAtion.id === taskEditPrcsID) {
          // Example action
          // setSomeState(TaskernAtion); // Uncomment if you want to set a state with the TaskernAtion
          console.log("TaskernAtion matches selected ID:", TaskernAtion);

          setTaskEditFloOrder(parseInt(taskEditFloOrder));
        } else {
          console.log("No match found or TaskernAtion is undefined.");
          if (TaskernAtion?.tasks?.length === 0) {
            console.log("<>>", 1);
            setTaskEditFloOrder(1); // Return 1 if the array is empty
          } else {
            console.log("<>>");
            const lastObject =
              TaskernAtion?.tasks[TaskernAtion?.tasks?.length - 1];
            const gettheFlowOrder = lastObject.task_flow_order; // Return the value of number_flow
            const AddCountFlowOrder = gettheFlowOrder + 1;

            console.log(AddCountFlowOrder, "getorder");
            setTaskEditFloOrder(parseInt(AddCountFlowOrder));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="tree-page-container">
      <div className="page-header mb-0">
        <nav aria-label="breadcrumb" className="mb-5 mt-5">
          <div id="clip">
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
              }}>
              <a
                href="/master/Org"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Masters
              </a>
            </span>
            <span
              style={{
                padding: "3px 20px",
                backgroundColor: "#FFA500",
                color: "white",
                display: "inline-block",
                clipPath:
                  "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                marginRight: "5px", // Adjusted margin for smaller gap
              }}>
              <a
                href="/master/tree"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}>
                Tree
              </a>
            </span>
          </div>
        </nav>
      </div>

      {/* Render the content of the selected organization */}
      {selectedOrgDataa && (
        <div className="org-content-container">
          {/* <h3>{selectedOrgDataa.org_name}</h3> */}
          <div className="display_space_between">
            {/* <h5 className="table_heading">Tree</h5> */}
            <Form.Group controlId="orgSelect">
              <Form.Control
                as="select"
                value={selectedOrg}
                onChange={handleOrgChange}
                className="custom-dropdown" // Apply the custom class here
              >
                {orgData.map((org) => (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          {renderProcesses(selectedOrgDataa.processes)}
        </div>
      )}

      {/* Modal to display process tasks */}
      {selectedProcess && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 20px 10px 20px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease", // Animation for transform and box-shadow
            }}
            className="modal-header-hover">
            <Modal.Title
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}>
              {capitalizeWords(selectedProcess.process_name)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              padding: "20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              transition: "transform 0.3s ease, box-shadow 0.3s ease", // Adding transition for smooth hover effect
            }}
            className="modal-body-hover">
            {renderTasks(selectedProcess.tasks)}
          </Modal.Body>
        </Modal>
      )}
      {/* Edit Process Modal */}
      {showEditModal && (
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
          size="lg">
          <Modal.Body>
            <Container className="process-form-container">
              <h2 className="text-center mb-4">Edit Process</h2>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Organization Dropdown */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="orgName">
                      <Form.Label>Organization</Form.Label>
                      <Form.Control
                        as="select"
                        name="orgName"
                        value={editOrgId}
                        onChange={(e) => {
                          setEditOrgId(e.target.value);
                          const ds2 = e.target.value;
                          SettingProcessOrderHandler(ds2);
                        }}
                        required>
                        <option value="">Select Organization</option>
                        {organizations.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.org_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  {/* Process Name */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processName">
                      <Form.Label>Process Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="processName"
                        value={editProcessName}
                        placeholder="Enter process name"
                        onChange={(e) => setEditProcessName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  {/* Process Flow Order */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processFlowOrder">
                      <Form.Label>Process Flow Order</Form.Label>
                      <Form.Control
                        type="number"
                        name="processFlowOrder"
                        value={editFlowOrder}
                        onChange={(e) => setEditFlowOrder(e.target.value)}
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  {/* Department Dropdown */}
                  <Form.Group controlId="department">
                    <Form.Label>Department</Form.Label>
                    <div className="department-select-wrapper">
                      <Select
                        isMulti
                        name="department"
                        value={departments
                          .filter((dept) => editDept.includes(dept.id))
                          .map((dept) => ({
                            label: dept.department_name,
                            value: dept.id,
                          }))}
                        options={departments.map((dept) => ({
                          label: dept.department_name,
                          value: dept.id,
                        }))}
                        onChange={(selected) =>
                          setEditDept(selected.map((item) => item.value))
                        }
                        classNamePrefix="select"
                        placeholder="Select Department"
                      />
                    </div>
                  </Form.Group>
                </Row>

                {/* Action Buttons */}
                <div className="form-actions mb-4">
                  <Button
                    variant="outline-light"
                    type="reset"
                    className="cancel-btn"
                    onClick={() => setShowEditModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="outline-light"
                    type="submit"
                    className="create-btn">
                    Update Process
                  </Button>
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      )}
      {/* Edit Task Modal */}
      <Modal
        show={showEditTaskModal}
        onHide={handleEditCloseTask}
        centered
        size="lg">
        <Modal.Body>
          <Container className="task-form-container">
            <h2 className="text-center mb-4">Task Details</h2>
            <Form>
              <Row>
                {/* Organization Name Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="org_id">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      as="select"
                      name="org_id"
                      value={taskEditOrgID}
                      onChange={(e) => setTaskEditOrgID(e.target.value)}
                      required>
                      <option value="">Select Organization</option>
                      {organizations.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.org_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* Process Name Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="process_id">
                    <Form.Label>Process Name</Form.Label>
                    <Form.Control
                      as="select"
                      name="process_id"
                      value={taskEditPrcsID}
                      onChange={(e) => {
                        const ds2 = e.target.value;
                        SettingTaskOrderHandler(ds2);
                        setTaskEditPrcsID(ds2);
                      }}
                      required>
                      <option value="">Select Process</option>
                      {processOptions.map((process) => (
                        <option key={process.key} value={process.value}>
                          {process.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* Department Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="dept_id">
                    <Form.Label>Department</Form.Label>
                    <Form.Control
                      as="select"
                      name="dept_id"
                      value={taskEditDepID} // This should reflect the selected department ID
                      onChange={handleDepartmentChange} // Handle department selection change
                      required>
                      <option value="">Select Department</option>
                      {departmentOptions.map((dept) => (
                        <option key={dept.key} value={dept.value}>
                          {dept.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* Department Role Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="dept_role_id">
                    <Form.Label>Department Role</Form.Label>
                    <Form.Control
                      as="select"
                      name="dept_role_id"
                      value={taskEditDeptRoleID}
                      onChange={(e) => setTaskEditDeptRoleID(e.target.value)}
                      required>
                      <option value="">Select Department Role</option>
                      {roleOptions.map((role) => (
                        <option key={role.value} value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* Task Name */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="task_name">
                    <Form.Label>Task Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="task_name"
                      value={taskEditTaskName}
                      placeholder="Task Name"
                      onChange={(e) => setTaskEditTaskName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* SLA Resolution Time */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="sla_resolution_time">
                    <Form.Label>SLA Resolution Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="sla_resolution_time"
                      value={taskEditSLA}
                      onChange={(e) => setTaskEditSLA(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Task Flow Order */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="task_flow_order">
                    <Form.Label>Task Flow Order</Form.Label>
                    <Form.Control
                      type="number"
                      name="task_flow_order"
                      value={taskEditFloOrder}
                      onChange={(e) => setTaskEditFloOrder(e.target.value)}
                      required
                      disabled
                    />
                  </Form.Group>
                </Col>
                {/* Users Table */}
               
                <Col md={12} style={{ padding: "10px" }}>
                  <Form.Group controlId="users_list">
                    <Form.Label>Assign Users</Form.Label>
                    {usersList.length > 0 ? (
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>User Name</th>
                            <th>User ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersList.map((user) => (
                            <tr key={user.user_id}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={selectedUsers.includes(user.user_id)} // Check if the user is in selectedUsers
                                  onChange={(e) =>
                                    handleUserSelection(
                                      user.user_id,
                                      e.target.checked
                                    )
                                  } // Handle user selection/deselection
                                />
                              </td>
                              <td>{user.user}</td>
                              <td>{user.user_id}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p>No users assigned for this department.</p>
                    )}
                  </Form.Group>
                </Col>

                {/* Is Document Required Checkbox */}
                <Col md={6} style={{ padding: "50px 10px" }}>
                  <Form.Group controlId="is_doc_upload_required">
                    <Form.Check
                      type="checkbox"
                      label="Is Document Required"
                      name="is_doc_upload_required"
                      checked={taskEditDocum}
                      onChange={(e) => setTaskEditDocum(e.target.checked)}
                    />
                  </Form.Group>
                </Col>

                {/* Task Description */}
                <Col md={12} style={{ padding: "10px" }}>
                  <Form.Group controlId="task_description">
                    <Form.Label>Task Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="task_description"
                      value={taskEditTaskDesc}
                      onChange={(e) => setTaskEditTaskDesc(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Form Actions */}
                <div className="form-actions mb-4">
                  <Button
                    variant="outline-light"
                    type="reset"
                    className="cancel-btn"
                    onClick={handleEditCloseTask}>
                    Cancel Task
                  </Button>
                  <Button
                    variant="outline-light"
                    className="create-btn"
                    onClick={(e) => handleSubmitTask(e)}
                    type="submit">
                    Update Task
                  </Button>
                </div>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tree;
