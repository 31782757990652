import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Badge } from "react-bootstrap";
import { Ticket } from "../components/DashComponents/Ticket";
import { formatDistanceToNow, parseISO } from "date-fns";
import {
  FcAddDatabase,
  FcList,
  FcProcess,
  FcCheckmark,
  FcExpired,
} from "react-icons/fc";
import Charts from "../components/DashComponents/Data/Chart";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import URL from "../API/Api";

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

// TicketData
const ticketData = [
  {
    name: "New Task",
    count: 2,
    icon: <FcAddDatabase />,
    background:
      "linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))",
    Graphbackground: ["rgb(250, 202, 207)", "rgb(250, 202, 207)"],
  },
  {
    name: "Unassigned Tasks",
    count: 17,
    icon: <FcList />,
    background: "linear-gradient(to right, #bbdefb, #e3f2fd)",
    Graphbackground: ["rgb(187, 222, 251)", "rgb(187, 222, 251)"],
  },
  {
    name: "Inprogress Tasks",
    count: 10,
    icon: <FcProcess />,
    background:
      "linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))",
    Graphbackground: ["rgb(247, 212, 253)", "rgb(247, 212, 253)"],
  },
  {
    name: "Closed Tasks",
    count: 9,
    icon: <FcCheckmark />,
    background:
      "linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))",
    Graphbackground: ["rgb(178, 251, 194)", "rgb(178, 251, 194)"],
  },

  {
    name: "Pending Approvals",
    count: 10,
    icon: <FcExpired />,
    background:
      "linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))",
    Graphbackground: ["rgb(255, 227, 171)", "rgb(255, 227, 171)"],
  },
];

const Dashboard = () => {
  const [parsedNotifications, setparsedNotifications] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationData, setNotificationData] = useState({});
  const [taskStatus, setTaskStatus] = useState(); // Store the current task status
  const [taskRemarks, setTaskRemarks] = useState(""); // Store the current task status
  const [taskResponseMsg, setTaskResponseMsg] = useState(""); // Store the current task status
  const [initialStatus, setInitialStatus] = useState(""); // Track the initial status for comparison
  const [isStatusChanged, setIsStatusChanged] = useState(false); // Track if the status has changed

  const [uploadedDocument, setUploadedDocument] = useState(null);

  // const storedNotification = localStorage.getItem("userNotification");
  const getUser = localStorage.getItem("user");
  const parsedUser = JSON.parse(getUser);
  // const parsedNotifications = JSON.parse(storedNotification);

  // console.log(parsedUser.data.id,"jjkll")
  useEffect(() => {
    NotificationCall();
  }, [modalVisible]);

  useEffect(() => {
    NotificationCall();
  }, []);

  const NotificationCall = () => {
    URL.get(
      `/api/taskConfigDetailsResponse/getUserNotifications/${parsedUser?.data.id}`
    )
      .then((res) => {
        // console.log(res.data.data,"mydept")
        setparsedNotifications(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  // console.log(parsedNotifications,"parsednotidifcation")

  //createGradient
  const createGradient = (ctx, colors) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);

    colors.forEach((color, index) => {
      const rgbaMatch = color.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d?\.?\d*))?\)/
      );
      if (rgbaMatch) {
        const [_, r, g, b, a] = rgbaMatch;
        gradient.addColorStop(
          index / (colors.length - 1),
          `rgba(${r}, ${g}, ${b}, ${a ? a : 1})` // Default alpha to 1 if not defined
        );
      } else {
        console.warn(`Invalid color format: ${color}`);
      }
    });

    return gradient;
  };

  // chart data
  const chartData = {
    labels: ticketData.map((ticket) => ticket.name),
    datasets: [
      {
        label: "Tasks",
        data: ticketData.map((ticket) => ticket.count),
        backgroundColor: ticketData.map((ticket) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          return createGradient(ctx, ticket.Graphbackground);
        }),
      },
    ],
  };

  // Function to open the modal and set the selected notification
  const openModal = (notification) => {
    setSelectedNotification(notification);
    setNotificationData(notification);

    console.log(notification, "mynitifff");

    setModalVisible(true);

    let TaskUpdateData = {
      is_read: 1,
    };
    // api/taskConfigDetailsResponse/11/updateIsReadStatus
    URL.put(
      `/api/taskConfigDetailsResponse/${notification.id}/updateIsReadStatus`,
      TaskUpdateData
    )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  // Function to close the modal
  const closeModal = () => {
    setModalVisible(false);
    setTaskStatus();
    setTaskRemarks();
    setTaskResponseMsg();
    setImagePreview();
    setSelectedNotification(null); // Clear the selected notification
  };

  const handleStatusChange = (e) => {
    setTaskStatus(e.target.value);
    setIsStatusChanged(true);
  };

  // Handling file selection
  // const handleFileChange = (e) => {
  //   setUploadedDocument(e.target.files[0]);
  // };
  const [imagePreview, setImagePreview] = useState("");

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setUploadedDocument(file);
        setImagePreview(reader.result); // Data URL of the image
      };

      reader.readAsDataURL(file); // Read the file as a data URL (base64 string)
    }
  };

  const handleStatusUpdate = async () => {
    const taskId = selectedNotification.id;
    const formData = new FormData();

    // Append text fields
    formData.append("task_name", selectedNotification.task.task_name);
    formData.append(
      "dep_name",
      selectedNotification.task.department.department_name
    );
    formData.append(
      "dep_role_name",
      selectedNotification.task.department_role.dep_role_name
    );
    formData.append(
      "slaResolution",
      selectedNotification.task.sla_resolution_time
    );
    formData.append("due_date", selectedNotification.task.due_date);
    formData.append(
      "task_description",
      selectedNotification.task.task_description
    );
    formData.append("status", taskStatus);
    formData.append("remarks", taskRemarks);
    formData.append("response_message", taskResponseMsg);
    formData.append(
      "task_config_detail_id",
      selectedNotification.task_config_detail.id
    );
    formData.append(
      "task_flow_order",
      selectedNotification?.task.task_flow_order
    );
    formData.append("process_id", selectedNotification?.task.process.id);
    formData.append("task_id", selectedNotification?.task.id);
    formData.append("user_id", parsedUser.data?.id);

    // task.task_flow_order
    // task.process.id
    // task.task.id
    // user_id
    console.log(formData, "parsedUser");
    // Append image if available
    if (uploadedDocument) {
      formData.append("uploaded_document", uploadedDocument);
    }

    // console.log(selectedNotification,"selectedNotification")

    try {
      const response = await URL.post(
        `api/taskConfigDetailsResponse/${taskId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setTaskStatus();
      setTaskRemarks();
      setTaskResponseMsg();
      setImagePreview();
      closeModal();
    } catch (error) {
      console.error("Error uploading the data", error);
    }
  };

  return (
    <div>
      <Row>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                New <br /> Tasks{" "}
              </>
            }
            count="15"
            icon={<FcAddDatabase />}
            background="linear-gradient(to right, rgb(250 202 207), rgb(255 204 188 / 18%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Unassigned <br /> Tasks{" "}
              </>
            }
            count="20"
            icon={<FcList />}
            background="linear-gradient(to right, #bbdefb, #e3f2fd)"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Inprogress <br /> Tasks{" "}
              </>
            }
            count="11"
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Closed <br /> Tasks{" "}
              </>
            }
            count="56"
            icon={<FcCheckmark />}
            background="linear-gradient(to right, rgb(178, 251, 194), rgb(178 251 194 / 29%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Pending <br />
                Approvals{" "}
              </>
            }
            count="0"
            icon={<FcExpired />}
            background="linear-gradient(to right, rgb(255, 227, 171), rgb(255 244 222 / 64%))"
            Graphbackground=""
          />{" "}
        </Col>
        <Col lg={2} md={4} sm={6} xs={12}>
          {" "}
          <Ticket
            ticket_name={
              <>
                Delayed <br /> Tasks{" "}
              </>
            }
            count="2"
            icon={<FcProcess />}
            background="linear-gradient(to right, rgb(247 212 253), rgb(225 190 231 / 30%))"
            Graphbackground=""
          />{" "}
        </Col>
      </Row>

      <Row>
        {/* Tabular Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          {/* <div className="chart-container p-2 m-2">
            {parsedNotifications ? <> {parsedNotifications?.map((item, index) => ( 
              <div key={index} 
              onClick={() => openModal(item)}
              style={{ cursor: 'pointer' }}
              className="alert alert-info"> 
             <b> A new task Alert : </b> A task {item.task.task_name} under the process of {item.process.process_name}. </div>
            )) }</> : (
              <div>No new notifications.</div>
            )}
          </div> */}
          <div className=" m-2 p-2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}>
              <h5>Notification</h5>
              <div>
                <span>
                  Pending :{" "}
                  <b>
                    {parsedNotifications &&
                      parsedNotifications.filter((c) => c.status == 0)
                        ?.length}{" "}
                  </b>
                </span>
                <span>
                  Total :{" "}
                  <b> {parsedNotifications && parsedNotifications.length} </b>{" "}
                </span>
              </div>
            </div>
            {parsedNotifications ? (
              <div style={{ height: "60vh", overflowY: "auto" }}>
                {parsedNotifications
                  ?.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((item, index) => (
                    <div className="notification_card" key={index}>
                      <div className="card notification-card notification-invitation">
                        {item.is_read == 0 ? (
                          <div className="card-body">
                            <table>
                              <tr>
                                <td style={{ width: "70%" }}>
                                  <div className="card-title">
                                    {/* Unread Notification Indicator at the Front */}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: "orange",
                                        borderRadius: "50%",
                                        marginRight: "10px", // Space between dot and text
                                      }}></span>
                                    <b>{item.task.task_name}</b> task under the
                                    <b>{item.task.process.process_name}</b>{" "}
                                    process, assigned to the
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item.task.department.department_name}
                                    </b>{" "}
                                    department.
                                  </div>
                                </td>
                                <td style={{ width: "30%" }}>
                                  <Badge
                                    bg="warning"
                                    style={{
                                      marginRight: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => openModal(item)}>
                                    View
                                  </Badge>
                                  <div
                                    style={{
                                      textAlign: "right",
                                      fontSize: "smaller",
                                      marginTop: "5px",
                                    }}>
                                    <i>
                                      {item.status === 0
                                        ? `Created ${formatDistanceToNow(
                                            parseISO(item.created_at),
                                            { addSuffix: true }
                                          )}`
                                        : `Updated ${formatDistanceToNow(
                                            parseISO(item.updated_at),
                                            { addSuffix: true }
                                          )}`}
                                    </i>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        ) : (
                          <div
                            className="card-body"
                            style={{ background: "#e5eaee" }}>
                            <table>
                              <tr>
                                <td style={{ width: "70%" }}>
                                  <div className="card-title">
                                    <b>{item.task.task_name}</b> task under the
                                    <b>{item.task.process.process_name}</b>{" "}
                                    process, assigned to the
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item.task.department.department_name}
                                    </b>{" "}
                                    department.
                                  </div>
                                </td>
                                <td style={{ width: "30%" }}>
                                  <Badge
                                    bg="warning"
                                    style={{
                                      marginRight: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => openModal(item)}>
                                    View
                                  </Badge>
                                  <div
                                    style={{
                                      textAlign: "right",
                                      fontSize: "smaller",
                                      marginTop: "5px",
                                    }}>
                                    <i>
                                      {item.status === 0
                                        ? `Created ${formatDistanceToNow(
                                            parseISO(item.created_at),
                                            { addSuffix: true }
                                          )}`
                                        : `Updated ${formatDistanceToNow(
                                            parseISO(item.updated_at),
                                            { addSuffix: true }
                                          )}`}
                                    </i>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div>No new notifications.</div>
            )}
          </div>
        </Col>

        {/* Charts Section */}
        <Col lg={6} md={6} sm={12} className="p-0 m-0">
          <Charts chartData={chartData} />
        </Col>
      </Row>

      {/* Modal to view the notification details */}
      {modalVisible && selectedNotification && (
        <div className="modal-overlay">
          <div className="modal-content" style={{ background: "#fff" }}>
            <h4>Task Detail</h4>
            {selectedNotification && (
              <div>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <strong>Process Name:</strong>
                    <input
                      type="text"
                      value={selectedNotification.task.process.process_name}
                      readOnly
                      className="form-control"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Task :</strong>
                    <input
                      type="text"
                      value={selectedNotification.task.task_name}
                      readOnly
                      className="form-control"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Department :</strong>
                    <input
                      type="text"
                      value={
                        selectedNotification.task.department.department_name
                      }
                      readOnly
                      className="form-control"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Department Role:</strong>
                    <input
                      type="text"
                      value={
                        selectedNotification.task.department_role.dep_role_name
                      }
                      readOnly
                      className="form-control"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>SLA Resolution :</strong>
                    <input
                      type="text"
                      value={selectedNotification.task.sla_resolution_time}
                      readOnly
                      className="form-control"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Due Date:</strong>
                    <input
                      type="text"
                      value={selectedNotification.task.due_date}
                      readOnly
                      className="form-control"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <strong>Task Description:</strong>
                    <textarea
                      value={selectedNotification.task.task_description}
                      readOnly
                      className="form-control"
                      rows={2} // Adjust the number of rows to fit your content
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col lg={6} md={6} sm={12}>
                    <strong>Uploaded Document:</strong>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {selectedNotification.status === 0 ? (
                      <div style={{ marginTop: "10px" }}>
                        <strong>Selected File:</strong>
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt=""
                            style={{ width: "100px", height: "50px" }}
                          />
                        )}
                        {/* {uploadedDocument?.name} */}
                      </div>
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                        {/* <strong>Selected File:</strong> {selectedNotification.uploaded_document} */}
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            selectedNotification.uploaded_document
                          }
                          alt=""
                          style={{ width: "100px", height: "50px" }}
                        />
                      </div>
                    )}
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Task Status:</strong>
                    <Form.Select
                      value={
                        selectedNotification.status === 0
                          ? taskStatus
                          : selectedNotification.status
                      }
                      onChange={handleStatusChange}
                      className="form-control">
                      <option value={0}>Open</option>
                      <option value={1}>Completed</option>
                      <option value={2}>Rejected</option>
                    </Form.Select>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Task Remarks:</strong>
                    <input
                      type="text"
                      value={
                        selectedNotification.status === 0
                          ? taskRemarks
                          : selectedNotification.remarks
                      }
                      className="form-control"
                      onChange={(e) => setTaskRemarks(e.target.value)}
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <strong>Response Message :</strong>
                    <input
                      type="text"
                      value={
                        selectedNotification.status === 0
                          ? taskResponseMsg
                          : selectedNotification.response_message
                      }
                      className="form-control"
                      onChange={(e) => setTaskResponseMsg(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col lg={6} md={6} sm={12}></Col>
                  <Col lg={6} md={6} sm={12}>
                    {isStatusChanged && selectedNotification.status === 0 && (
                      <Button
                        style={{ width: "150px" }}
                        variant="primary"
                        onClick={handleStatusUpdate}>
                        Update
                      </Button>
                    )}
                    <Button
                      style={{ width: "150px", marginLeft: "10px" }}
                      onClick={closeModal}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
